import React, {Component} from 'react';
import styles from "../templates/default.module.css";

class MobileBgWrapper extends Component {
    render() {
        return (
            <div className={styles.mobileBgWrapper}>
                    {this.props.children}
            </div>
        );
    }
}

export default MobileBgWrapper;