import React, {Component} from 'react';
import {Menu, Rail, Segment,} from 'semantic-ui-react';
import {Link} from "gatsby";
import styles1 from "./aboutusnavigation.module.css";
import DesktopContainer from "../../container/DesktopContainer";

class AboutUsNavigation extends Component {

    render() {
        return (
            <DesktopContainer>
                <Rail position='right' className={styles1.rail}>
                    <Segment raised className={styles1.menuSegment}>
                        <Menu secondary vertical className={styles1.menu}>
                            <Link to="/ueber-uns/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Über uns</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/schirmherr/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Schirmherr</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/vorstand/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Vorstand</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/kuratorium/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Kuratorium</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/beirat/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Wissenschaftlicher Beirat</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/komitee/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Preisvergabekomitee</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/geschaeftsstelle/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Geschäftsstelle</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/grusswort-vorstand/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Grußwort Vorstandsvorsitzende</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/grusswort-schirmherr/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Grußwort Schirmherr</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/grusswort-ddg/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Grußwort DDG</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/grusswort-bvdd/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Grußwort BVDD</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/grusswort-apd/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Grußwort APD</Menu.Item>
                            </Link>
                            <Link to="/ueber-uns/grusswort-nipd/" activeClassName={styles1.activeLink}>
                                <Menu.Item className={styles1.menuItem}>Grußwort NipD</Menu.Item>
                            </Link>
                        </Menu>
                    </Segment>
                </Rail>
            </DesktopContainer>
        );
    }
}


export default AboutUsNavigation;