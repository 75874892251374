import React from 'react';
import {Container, Image,} from 'semantic-ui-react';
import profileImg1 from "../../assets/schirmherr.jpg";
import componentStyles from "../../templates/default.module.css";

const GreetingsPatron = () => (
    <Container>
        <Image src={profileImg1} floated={'left'} size={'medium'} className={componentStyles.greetingsImg}/>
        <p>Sehr verehrte Damen und Herren,</p>
        <p>das ist ein großer Tag: die Gründung der „Deutschen Stiftung Kinderdermatologie“. Sie ist erst möglich durch ein großes Herz, nämlich das große Herz von Frau Dr. Nicole Inselkammer, meiner früheren Mitarbeiterin.</p>
        <p>Als Frau Dr. Inselkammer vor einige Zeit zu mir kam und mir erzählte, sie wolle etwas ganz anderes machen als „nur eine Hautarztpraxis“, sie trage sich mit dem Gedanken, eine Stiftung zu gründen, und ob das Thema „Kinderdermatologie“ da geeignet sei, da habe ich sofort zugestimmt und sie beglückwünscht und ihr versprochen, das zu unterstützen. Dieses Gebiet ist tatsächlich ein Feld, das Unterstützung braucht, das in Deutschland vernachlässigt ist. Diese Stiftung macht Sinn!</p>
        <p>Erstens gibt es immer mehr betroffene Kinder, vor allem durch die starke Zunahme von Allergien, Neurodermitis und anderen Erkrank-ungen wie Nahrungsmittelallergien etc.</p>
        <p>Zweitens gibt es immer schwierigere komplexere Krankheitsbilder, wie wir das tagtäglich in unserer Ambulanz und in den niedergelassenen Praxen sehen.</p>
        <p>Drittens wird die Versorgung immer schwieriger. Das hat auch mit den finanziellen Engpässen im Gesundheitssystem zu tun, unter dem ganz besonders Kinder leiden. Wenn sie 12 Jahre alt werden, werden z. B. die Pflegecremes zur Restaurierung der gestörten Hautbarriere nicht mehr erstattet. Diese stellen jedoch das Wichtigste für den Neurodermitiker dar, der eben an einer Störung der Hautbarriere mit entsprechenden Notwendigkeiten zur rück-fettenden Maßnahmen leidet. Ich sage: „Das wäre genauso, wie wenn man dem Diabetiker das Insulin nicht mehr bezahlen wollte; das ist halt ein genetischer Defekt, das ist seine Eigenheit, das muss er selber zahlen!“</p>
        <p>Aber auch hausintern, nämlich im Bereich der Ärzteschaft, im Bereich der Ausbildung, Weiterbildung und Fortbildung und in der Organisation dieses Gebietes müssen wir unsere Hausaufgaben machen.</p>
        <p>Es gibt Länder, wo die Versorgung hautkranker Kinder wesentlich besser geregelt ist als in Deutschland. Und leider haben die Defizite bei uns zugenommen.</p>
        <p>Als ich meine Assistenzarztzeit an der Hautklinik der LMU in München begann, hatten wir selbstverständlich eine Kinderstation, das war meine erste Station. Damals hatten fast alle deutschen Hautkliniken eigene Kinderstationen.</p>
        <p>Heute hat fast keine Hautklinik mehr eine Kinderstation, fast keine Hautklinik nimmt überhaupt noch Kinder auf. Aber dafür keine Hautabteilungen in Kinderkliniken. Die haut-kranken Kinder werden einfach „zwischendurch“, ein bisschen konsiliarisch, aber eigentlich nicht optimal, versorgt. </p>
        <p>Das Problem hautkranker Kinder existiert nicht nur in Deutschland, es ist ein Riesenproblem in den Ländern der Dritten Welt. Ich war vor vier Wochen in Afrika in Tansania und habe dort schwerste Fälle von Neurodermitis gesehen. Die sogenannte Hygienehypothese gilt nur für Atemwege: Neurodermitis/atopisches Ekzem gibt es auf dem Bauernhof und im Urwald. Das hat nichts oder nur wenig mit westlicher Zivilisation zu tun. Auch hier müssten wir etwas tun.</p>
        <p>Wir haben einen Traum. Mit einer Stiftung, die aufgrund des freien Stifterwillens unkonven-tionelle Wege gehen kann, könnten wir uns ein spezielles Curriculum „Kinderdermatologie“ auf dem Weg zu einer anerkannten Sub-spezialisierung denken, die man als Hautarzt und als Kinderarzt mit entsprechender Qualifikation und konkret definierten Weiterbildungsinhalten erwerben könnte.</p>
        <p>Es wäre auch ein Startzeichen, wenn an einer deutschen Universität eine eigene „Abteilung für Kinderdermatologie“ gegründet werden könnte. So etwas wäre über einen Stiftungslehrstuhl möglich. Es gibt so Vieles, was man tun kann auf diesem Gebiet. </p>
        <p>Damit aus diesen Träumen Wirklichkeit wird, brauchen wir Ihre Hilfe! Auch auf Ihre Unterstützung hoffend verbleibe ich</p>
        <p>mit freundlichen Grüßen</p>
        <p>Prof. Dr. med. Dr. phil. Johannes Ring</p>
    </Container>

);

export default GreetingsPatron;